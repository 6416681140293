<template>
	<div class="warehouseReceipt" v-loading="loading">
		<div class="warehouseReceipt-title">盘盈开单</div>
		<div class="warehouseReceipt-form">
			<el-form ref="form" :model="form" label-width="100px">
				<el-row :gutter="24">
					<el-col :span="6">
						<el-form-item label="盘点日期" required>
							<el-date-picker v-model="form.date" type="date" placeholder="选择入库日期"
								value-format="yyyy-MM-dd" style="width: 100%;">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="盘点仓库" required>
							<el-select v-model="form.warehouse_id" filterable placeholder="请选择入库仓库"
								style="width: 100%;">
								<el-option v-for="item in warehouseList" :key="item.id" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="6">
						<el-form-item label="经办人" required>
							<el-input v-model="form.handler" placeholder="请输入经办人"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="备注">
							<el-input v-model="form.remark" placeholder="请输入备注"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="盘点金额" required>
							<el-input v-model="form.amount" placeholder="请输入应付金额"></el-input>
						</el-form-item>
					</el-col>


				</el-row>
			</el-form>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus" @click.stop="addFn()">新增</el-button>
			</div>
			<el-table :data="tableData" border :header-cell-style="{
				background: '#f5f7fa',
				fontWeight: 'bold',
				color: '#303133'
			}">
				<el-table-column label="商品名称" align="center" width="180">
					<template slot-scope="scope">
						<span>{{ scope.row.goods_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="商品主图" align="center">
					<template slot-scope="scope">
						<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
							:preview-src-list="[scope.row.goods_pic]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column label="商品卖点" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.goods_intro }}</span>
					</template>
				</el-table-column>
				<el-table-column label="自定义属性" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.attribute }}</span>
					</template>
				</el-table-column>
				<el-table-column label="参考成本价" align="center">
					<template slot-scope="scope">
						<el-input v-model="scope.row.cost" placeholder="请输入商品参考成本价"
							@blur="blurInputCount(scope.row, 'table', 1)"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="盘盈数量" align="center" width="180">
					<template slot-scope="scope">
						<el-input v-model="scope.row.count" placeholder="请输入商品盘盈数量"
							@blur="blurInputCount(scope.row, 'table', 2)"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="小计" align="center">
					<template slot-scope="scope">
						<el-input v-model="scope.row.amount" placeholder="请输入商品小计"
							@blur="blurInputCount(scope.row, 'table', 3)"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="danger" size="mini" @click.stop="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 新增编辑 -->
		<el-dialog title="添加商品" :visible.sync="dialogVisible" width="70%" :close-on-click-modal="false" v-el-drag-dialog
			@close="closeDialog">
			<div class="search" style="padding: 0;margin-bottom: 10px;">
				<div class="search-l">
					<div class="l-item">
						<div class="item-label">商品名称:</div>
						<div class="item-input">
							<el-input v-model="goodsVal" placeholder="请输入商品名称" />
						</div>
					</div>
					<div class="l-item">
						<el-button type="warning"
							@click.stop="goodsPage = 1, goodsLoading = true, goodsList()">搜索</el-button>
					</div>
					<i></i>
					<i></i>
					<i></i>
					<i></i>
				</div>
			</div>
			<div style="height:500px;" class="scrollbar">
				<el-scrollbar style="height:100%">
					<el-table :data="goodsData" border v-loading="goodsLoading" :header-cell-style="{
						background: '#f5f7fa',
						fontWeight: 'bold',
						color: '#303133'
					}" :header-cell-class-name="leftheaderStyle">
						<el-table-column type="selection" width="55" align="center">
							<template slot-scope="scope">
								<el-checkbox :value="scope.row.goodsChecked ? scope.row.goodsChecked : false"
									@change="goodsItemBox(scope.row, $event)">
								</el-checkbox>
							</template>
						</el-table-column>
						<el-table-column label="商品名称" align="center" width="180">
							<template slot-scope="scope">
								<span>{{ scope.row.goods_name }}</span>
							</template>
						</el-table-column>
						<el-table-column label="商品主图" align="center">
							<template slot-scope="scope">
								<el-image style="width: 50px; height: 50px" :src="scope.row.goods_pic"
									:preview-src-list="[scope.row.goods_pic]">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column label="商品卖点" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.goods_intro }}</span>
							</template>
						</el-table-column>
						<el-table-column label="自定义属性" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.attribute }}</span>
							</template>
						</el-table-column>
						<el-table-column label="参考成本价" align="center">
							<template slot-scope="scope">
								<el-input v-model="scope.row.cost" placeholder="请输入商品参考成本价"
									@blur="blurInputCount(scope.row, 'dialog', 1)"
									:disabled="scope.row.goodsChecked ? false : true"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="盘盈数量" align="center">
							<template slot-scope="scope">
								<el-input v-model="scope.row.count" placeholder="请输入商品盘盈数量"
									@blur="blurInputCount(scope.row, 'dialog', 2)"
									:disabled="scope.row.goodsChecked ? false : true"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="小计" align="center">
							<template slot-scope="scope">
								<el-input v-model="scope.row.amount" placeholder="请输入商品小计"
									@blur="blurInputCount(scope.row, 'dialog', 3)"
									:disabled="scope.row.goodsChecked ? false : true"></el-input>
							</template>
						</el-table-column>
					</el-table>
				</el-scrollbar>
			</div>
			<el-pagination class="page" @size-change="goodsPageNum = $event, goodsLoading = true, goodsList()"
				@current-change="goodsPage = $event, goodsLoading = true, goodsList()" :current-page="goodsPage"
				:page-sizes="goodsPageSizes" layout="total, sizes, prev, pager, next, jumper" :page-size="goodsPageNum"
				:total="goodsTotal">
			</el-pagination>
			<span slot="footer" class="dialog-footer">
				<el-button type="warning" @click.stop="dialogConfirmFn">确 定</el-button>
			</span>
		</el-dialog>
		<div class="warehouseReceipt-footer">
			<el-button type="success" @click.stop="confirmFn(1)">保存并查看</el-button>
			<el-button type="success" @click.stop="confirmFn(2)">保存并继续</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					date: '',
					warehouse_id: '',
					erp_supplier_id: '',
					handler: '',
					amount: '',
					remark: '',
					settlemented_status: 0
				},
				tableData: [],
				warehouseList: [], //仓库
				// 新增、编辑
				dialogVisible: false,
				inputGoodsList: [],
				goodsVal: '',
				goodsData: [],
				goodsLoading: true,
				goodsPage: 1,
				goodsPageNum: 10,
				goodsTotal: 0,
				goodsPageSizes: [10, 50, 200, 1000],
				loading: false
			}
		},
		mounted() {
			this.getWarehouse()
			if (this.$route.query.id) {
				this.getDetails()
			}
		},
		methods: {
			// 订单详情
			getDetails() {
				this.$http.post(`/erp/v1/pyd/index`, {
					id: this.$route.query.id
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.goodsLoading = false
					if (code == 200) {
						this.form.date = data.date
						this.form.warehouse_id = data.warehouse_id
						this.form.handler = data.handler
						this.form.amount = data.amount
						this.form.remark = data.remark
						this.tableData = JSON.parse(JSON.stringify(data.order_goods).replace(/"goods_id"/g,
							'"id"'))
						setTimeout(() => {
							this.calculatePrice()
						}, 500);
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 仓库
			getWarehouse() {
				this.$http.post(`erp/v1/warehouse/all_zong`, {
					pageNum: 100
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.warehouseList = data
						if (this.warehouseList) {
							this.form.warehouse_id = this.warehouseList[0].id
						}
					} else {
						this.$message.error(msg);
					}
				});
			},

			// 新增
			addFn() {
				if (!this.form.warehouse_id) {
					this.$message.error('请选择盘点仓库');
					return
				}

				this.dialogVisible = true
				this.goodsList()

			},
			// 删除
			removeFn(item) {
				let index = this.tableData.indexOf(item)
				this.tableData.splice(index, 1)
				this.calculatePrice()
			},
			// 隐藏表头的选择框
			leftheaderStyle({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex === 0) {
					return "seltAllbtnDis"
				}
			},
			// 单个选择商品
			goodsItemBox(item, e) {
				let index = this.tableData.indexOf(item.id)
				if (e == true) {
					this.$set(item, 'goodsChecked', true)
					this.tableData.push(item)
				} else {
					this.$set(item, 'goodsChecked', false)
					this.tableData.splice(index, 1)
				}
			},
			// 商品列表
			goodsList() {
				this.$http.post(`erp/v1/goods/lst`, {
					page: this.goodsPage,
					pageNum: this.goodsPageNum,
					key: this.goodsVal
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.goodsLoading = false
					if (code == 200) {
						this.goodsData = data.rows
						this.goodsTotal = data.total
						this.goodsData.forEach(i => {
							this.$set(i, 'count', 1)
							this.$set(i, 'amount', Number(i.cost) * i.count)
							this.tableData.forEach(j => {
								if (i.id == j.id) {
									this.$set(i, 'cost', j.cost)
									this.$set(i, 'count', j.count)
									this.$set(i, 'amount', Number(j.cost) * j.count)
									this.$set(i, 'goodsChecked', true)
								}
							})
						})
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 新增编辑商品确定
			dialogConfirmFn() {
				this.goodsVal = ''
				this.dialogVisible = false
				setTimeout(() => {
					this.calculatePrice()
				}, 500);
			},
			// 关闭弹窗
			closeDialog() {
				this.tableData = this.tableData.filter(item => item.count != undefined);
				setTimeout(() => {
					this.calculatePrice()
				}, 500);
			},
			// 计算价格
			calculatePrice() {
				this.inputGoodsList = []
				this.form.amount = 0
				this.tableData.forEach(item => {
					this.$set(item, 'amount', Number(item.cost) * item.count)
					// 实收
					this.form.amount += Number(item.cost) * Number(item.count)
					this.inputGoodsList.push({
						goods_id: item.id,
						count: item.count,
						price: item.cost,
					})
				})
				this.form.amount = this.form.amount.toFixed(2)
			},
			// 操作商品数量
			blurInputCount(item, type, status) {
				if (status == 3) {
					this.$set(item, 'cost', Number(item.amount) / item.count)
					item.cost = item.cost.toFixed(2)
				} else {
					this.$set(item, 'amount', Number(item.cost) * item.count)
				}
				if (type == 'dialog') {
					this.tableData.forEach(i => {
						if (i.id == item.id) {
							i.cost = item.cost
							i.count = item.count
							i.amount = item.amount
						}
					})
				} else {
					setTimeout(() => {
						this.calculatePrice()
					}, 500);
				}
			},
			// 开单
			confirmFn(type) {
				if (!this.form.date) {
					this.$message.error('请选择盘点日期');
					return
				}
				if (!this.form.warehouse_id) {
					this.$message.error('请选择仓库');
					return
				}
				if (!this.form.handler) {
					this.$message.error('请输入经办人');
					return
				}
				if (this.inputGoodsList.length <= 0) {
					this.$message.error('请选择商品');
					return
				}
				this.loading = true
				this.$http.post(`erp/v1/pyd/add`, {
					...this.form,
					goods_list: JSON.stringify(this.inputGoodsList),
					source_order_id: this.$route.query.id ? this.$route.query.id : ''
				}).then((res) => {
					this.loading = false
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						if (type == 1) {
							this.$router.push("/warehousePanyingManagement");
							// 存储路由
							sessionStorage.setItem('activeMenu', 'warehousePanyingManagement');
							setTimeout(() => {
								window.location.reload();
							}, 1000)
						} else {
							this.$message.success('操作成功');
							setTimeout(() => {
								window.location.reload();
							}, 1000)
						}
					} else {
						this.$message.error(msg);
					}
				});
			}
		},
	}
</script>
<style lang="scss" scoped>
	.warehouseReceipt {
		.warehouseReceipt-title {
			text-align: center;
			font-size: 30px;
			margin-bottom: 20px;
		}

		.warehouseReceipt-form {
			background: #fff;
			padding: 30px 20px 10px 20px;
			border-radius: 10px;
			margin-bottom: 20px;
		}

		.warehouseReceipt-footer {
			display: flex;
			justify-content: center;
			margin-top: 30px;
		}
	}

	.page {
		margin-top: 20px;
		text-align: right;
	}
</style>